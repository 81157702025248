import axios from "axios";
import authHeader from "@/services/auth.header";
const API_URL = process.env.VUE_APP_API_URL;


class StatsService {
    // STORE
    async getStore (params) {
        return await axios.get( API_URL + 'store/', { headers:authHeader(), params:params })
    }
    // WINE
    async getType ( params ) {
        return await axios.get(API_URL + 'type/', { headers: authHeader(), params: params })
    }
    async getBottleSellDbD(params) {
        return await axios.get(API_URL + 'stats/', { headers: authHeader(), params:params })
    }
    // WAREHOUSE
    async getWarehouse( params ) {
        return await axios.get( API_URL + "warehouse/", { headers:authHeader(), params:params })
    }
    // CASSE E BISTROT
    // DASHBOARD STATS
    async getUltimiIncassi(params) {
        return await axios.get(API_URL + 'dashboard/stats/ultimi_incassi/', { headers:authHeader(), params:params })
    }
    async getMagazzinoStats(params) {
        return await axios.get(API_URL + 'dashboard/stats/dati_magazzino/', { headers:authHeader(), params:params })
    }
    async getOrderStats(params) {
        return await axios.get(API_URL + 'dashboard/stats/ultimi_ordini', { headers: authHeader(), params:params})
    }
    async getAnalyticsByDate(params) {
        return await axios.get(API_URL + 'dashboard/stats/analytics', { headers: authHeader(), params:params })
    }
    async getLastPrice(params) {
        return await axios.get(API_URL + 'dashboard/stats/last_price', {headers:authHeader(), params:params})
    }

}


export  default new StatsService()