<template>
  <v-card
      class="card-shadow border-radius-xl px-4 py-5"
      :class="$route.name === 'VrDefault' ? 'bg-gradient-default' : ''"
      elevation="5"
  >
    <div
        class="
        bg-gradient-success
        shadow-success
        border-radius-lg
        py-3
        pe-1
        mb-6
        mt-n12
      "
    >
      <div class="chart-area">
        <canvas
            :height="$route.name === 'VrDefault' ? '370' : '170'"
            :id="salesViewsID"
        >
        </canvas>
      </div>
    </div>
    <h6
        class="ms-2 mt-6 mb-0 text-h6 font-weight-bold"
        :class="$route.name === 'VrDefault' ? 'text-white' : 'text-typo'"
    >
      Vendite Giorno per Giorno
    </h6>
    <p class="text-sm ms-2 text-body font-weight-light">
      Giorno migliore: {{ this.giornoConPiuVendite }}<br>
      Giorno peggiore: {{ this.giornoConMenoVendite }}<br>
      Media Giornaliera: {{ parseFloat(this.mediaVendite).toFixed(2) }}
    </p>
    <hr class="horizontal dark mb-4 mx-2"/>
    <div class="d-flex text-body mx-2">
      <i class="material-icons-round text-sm my-auto me-1">schedule</i>
      <p class="mb-0 text-sm font-weight-light text-body">updated 4 min ago</p>
    </div>
  </v-card>
</template>

<script>
import Chart from "chart.js/auto";

export default {
  name: "card-daily-sales",
  props: {
    chartData: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      salesViewsID: "salesViews",
      chartInstance: null, // Aggiungi qui l'istanza del grafico
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.renderChart(); // Richiama renderChart quando chartData cambia
      },
    },
  },
  computed: {
    giornoConPiuVendite() {
      if (!this.chartData.length) return null;
      const maxVendite = Math.max(...this.chartData.map(item => item.total));
      const giornoMaxVendite = this.chartData.find(item => item.total === maxVendite);
      return giornoMaxVendite ? giornoMaxVendite.date : 'N/A';
    },
    giornoConMenoVendite() {
      if (!this.chartData.length) return null;
      const minVendite = Math.min(...this.chartData.map(item => item.total));
      const giornoMinVendite = this.chartData.find(item => item.total === minVendite);
      return giornoMinVendite ? giornoMinVendite.date : 'N/A';
    },
    mediaVendite() {
      if (!this.chartData.length) return 0;
      const sommaVendite = this.chartData.reduce((acc, item) => acc + item.total, 0);
      return sommaVendite / this.chartData.length;
    }
  },
  async mounted() {
    this.renderChart()
  },
  methods: {
    renderChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy(); // Distrugge l'istanza esistente se esiste
      }
      const labels = this.chartData.map(item => item.date);
      const data = this.chartData.map(item => item.total);
      this.chartInstance = new Chart(document.getElementById(this.salesViewsID).getContext("2d"), {
        type: "line",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Vendite Giornaliere",
              tension: 0,
              pointRadius: 5,
              pointBackgroundColor: "rgba(255, 255, 255, .8)",
              pointBorderColor: "transparent",
              borderColor: "rgba(255, 255, 255, .8)",
              borderWidth: 4,
              backgroundColor: "transparent",
              fill: true,
              data: data,
              maxBarThickness: 6,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
                color: "rgba(255, 255, 255, .2)",
              },
              ticks: {
                display: true,
                color: "#f8f9fa",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Plus Jakarta Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
            x: {
              grid: {
                drawBorder: false,
                display: false,
                drawOnChartArea: false,
                drawTicks: false,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#f8f9fa",
                padding: 10,
                font: {
                  size: 14,
                  weight: 300,
                  family: "Plus Jakarta Sans",
                  style: "normal",
                  lineHeight: 2,
                },
              },
            },
          },
        },
      });
    },
  }
}
</script>
