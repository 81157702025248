var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-overlay',{attrs:{"value":_vm.progress}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":70,"width":7,"color":"purple"}})],1),_c('v-card',{staticClass:"card-shadow border-radius-xl",attrs:{"elevation":"5"}},[_c('div',{staticClass:"card-header-padding"},[_c('div',{staticClass:"font-weight-bold text-h4 text-typo mb-0"},[_vm._v(" Pagina Statistiche ")])])]),_c('v-card',{staticClass:"card-shadow border-radius-xl mt-3",attrs:{"elevation":"5"}},[_c('v-card-title',[_vm._v(" Filtri ")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-select',{attrs:{"items":_vm.stores,"item-text":"store_name","item-value":"id","color":"pink","label":"Scegli lo Store","chips":"","small-chips":"","clearable":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":"","color":"indigo","text-color":"white"}},[_c('span',[_vm._v(_vm._s(item.store_name))])]):_vm._e()]}}]),model:{value:(_vm.storeSelected),callback:function ($$v) {_vm.storeSelected=$$v},expression:"storeSelected"}}),_c('small',{staticClass:"text-color-light"},[_vm._v("Store con id: "+_vm._s(_vm.storeSelected))])],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.warehouses,"item-text":"name","item-value":"id","color":"pink","label":"Scegli il  Magazzino","chips":"","deletable-chips":"","small-chips":"","item-color":"pink","multiple":"","hint":("Magazzino selezionato " + _vm.warehouseSelected),"clearable":""},model:{value:(_vm.warehouseSelected),callback:function ($$v) {_vm.warehouseSelected=$$v},expression:"warehouseSelected"}})],1),_c('v-col',[_c('v-select',{attrs:{"items":_vm.wineTypes,"item-text":"name","item-value":"id","item-color":"pink","chips":"","small-chips":"","deletable-chips":"","multiple":"","clearable":"","label":"Scegli il Tipo","hint":("Tipo selezionato " + _vm.wineTypesSelected)},model:{value:(_vm.wineTypesSelected),callback:function ($$v) {_vm.wineTypesSelected=$$v},expression:"wineTypesSelected"}})],1),_c('v-col',[_c('v-select',{attrs:{"label":"Tipo di Statistica","items":_vm.statistica,"item-text":"name","item-value":"value","item-color":"pink","chips":"","small-chips":"","color":"pink","clearable":"","hint":("Tipo statistica " + _vm.statisticaSelected),"disabled":_vm.storeSelected === null},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('v-chip',{attrs:{"small":"","color":"pink","text-color":"white"}},[_c('span',[_vm._v(_vm._s(item.name))])]):_vm._e()]}}]),model:{value:(_vm.statisticaSelected),callback:function ($$v) {_vm.statisticaSelected=$$v},expression:"statisticaSelected"}})],1),_c('v-col',[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Scegli Range di Date","prepend-icon":"mdi-calendar"},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"scrollable":"","color":"pink","range":"","locale":"it-it"},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1),_c('small',[_vm._v(_vm._s(this.dateRangeText))])],1)],1)],1)],1),_c('v-card',{staticClass:"mt-3 card-shadow border-radius-xl",attrs:{"elevation":"5"}},[_c('v-card-title'),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.showGrafico)?_c('grafico-barre',{attrs:{"chart-data":_vm.risultato}}):_vm._e()],1),_c('v-col',[(_vm.showGrafico)?_c('grafico-torta',{attrs:{"chart-data":_vm.valoreMagazzino}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }