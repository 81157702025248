<template>
  <div>
    <v-overlay :value="progress">
      <v-progress-circular indeterminate :size="70" :width="7" color="purple">
      </v-progress-circular>
    </v-overlay>
    <v-card class="card-shadow border-radius-xl" elevation="5">
      <div class="card-header-padding">
        <div class="font-weight-bold text-h4 text-typo mb-0">
          Pagina Statistiche
        </div>
      </div>
    </v-card>
    <v-card class="card-shadow border-radius-xl mt-3" elevation="5">
      <v-card-title>
        Filtri
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
               v-model="storeSelected"
                :items="stores"
                item-text="store_name"
                item-value="id"
                color="pink"
                label="Scegli lo Store"
                chips
                small-chips
                clearable
            >
              <template v-slot:selection="{ item, index }">
                <v-chip small color="indigo" text-color="white" v-if="index === 0">
                  <span>{{ item.store_name }}</span>
                </v-chip>
              </template>
            </v-select>
            <small class="text-color-light">Store con id: {{ storeSelected }}</small>
          </v-col>
          <v-col>
            <v-select
                v-model="warehouseSelected"
                :items="warehouses"
                item-text="name"
                item-value="id"
                color="pink"
                label="Scegli il  Magazzino"
                chips
                deletable-chips
                small-chips
                item-color="pink"
                multiple
                :hint="`Magazzino selezionato ${warehouseSelected}`"
                clearable
            >
              <!--              <template v-slot:selection="{ item, index }">-->
              <!--                <v-chip small color="indigo" text-color="white" v-if="index === 0">-->
              <!--                  <span>{{ item.name }}</span>-->
              <!--                </v-chip>-->
              <!--              </template>-->
            </v-select>
          </v-col>
          <v-col>
            <v-select
                v-model="wineTypesSelected"
                :items="wineTypes"
                item-text="name"
                item-value="id"
                item-color="pink"
                chips
                small-chips
                deletable-chips
                multiple
                clearable
                label="Scegli il Tipo"
                :hint="`Tipo selezionato ${wineTypesSelected}`"
            >
              <!--              <template v-slot:selection="{ items, index }"  v-if="index === 0">-->
              <!--                <v-chip v-for="item in items" :key="item.id" small color="pink" text-color="white">-->
              <!--                  <span>{{ index }}{{ item.name }}</span>-->
              <!--                </v-chip>-->
              <!--              </template>-->
            </v-select>
          </v-col>
          <v-col>
            <v-select
                label="Tipo di Statistica"
                v-model="statisticaSelected"
                :items="statistica"
                item-text="name"
                item-value="value"
                item-color="pink"
                chips
                small-chips
                color="pink"
                clearable
                :hint="`Tipo statistica ${statisticaSelected}`"
                :disabled="storeSelected === null"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0" small color="pink" text-color="white">
                  <span>{{ item.name }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col>
            <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="dates"
                    label="Scegli Range di Date"
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="dates"
                  scrollable
                  color="pink"
                  range
                  locale="it-it"
              >
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                    text
                    color="primary"
                    @click="save(dates)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
            <small>{{ this.dateRangeText }}</small>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mt-3 card-shadow border-radius-xl" elevation="5">
      <v-card-title></v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <grafico-barre v-if="showGrafico" :chart-data="risultato"></grafico-barre>
          </v-col>
          <v-col>
            <grafico-torta v-if="showGrafico" :chart-data="valoreMagazzino"></grafico-torta>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
// .then(resp => {}).catch(error => { console.log(error)}).finally()
import StatsService from "@/services/stats.service";
import GraficoBarre from "@/views/Pages/Reports/GraficoBarre.vue";
import GraficoTorta from "@/views/Pages/Reports/GraficoTorta.vue";
export default {
  components: {
    GraficoBarre,
    GraficoTorta
  },
  data() {
    return {
      progress: false,
      defaultParams: {},
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dates:[],
      menu: false,
      modal: false,
      // STORE
      stores: [],
      storeSelected: null,
      // WAREHOUSE
      warehouses: [],
      warehouseSelected: [],
      // WINES
      wineTypes: [],
      wineTypesSelected: [],
      // STATISTICA
      statistica: [
        {name: "Bottiglie Vendute", value: "bottle_sell"},
        {name: "Valore Magazzino", value: "magazzino_value"},
      ],
      statisticaSelected: "",
      risultato: null,
      valoreMagazzino: null,
      showGrafico: false
    }
  },
  watch: {
    storeSelected(newValue) {
      if (newValue) {
        this.getWarehouse()
        this.getType()
      }
    },
    statisticaSelected(value) {
      if (value) {
        this.showGrafico = false
        const params = {
          year: 2024,
          month: this.getMeseCorrente(),
          date_range: {
            start_date: this.dates[0],
            end_date: this.dates[1]
          },
          warehouse_ids: JSON.stringify(this.warehouseSelected),
          store: this.storeSelected,
          stats_type: value
        }
        StatsService.getBottleSellDbD(params).then(resp => {
          if (this.statisticaSelected === 'bottle_sell') {
            this.risultato = resp.data
          }
          if (this.statisticaSelected === 'magazzino_value') {
            this.valoreMagazzino = resp.data
          }
          this.showGrafico = true
        }).catch(error => {
          console.log(error)
        }).finally()
        console.log(value)
      }
    },
    dates(value) {
      if (value.length > 1 ) {
        console.log(value)
      }
    }
  },
  computed: {
    dateRangeText () {
        return this.dates.join(' ~ ')
      },
  },
  created() {
    this.getStore()
  },
  methods: {
    // DATE
    getMeseCorrente() {
      const oggi = new Date();
      return oggi.getMonth() + 1; // Aggiungi 1 perché gennaio = 0
    },
    // STORE
    getStore() {
      StatsService.getStore().then(resp => {
        this.stores = resp.data.results
      }).catch(error => {
        console.log(error)
      }).finally()
    },
    // WAREHOUSE
    getWarehouse() {
      const params = {
        store: this.storeSelected
      }
      StatsService.getWarehouse(params).then(resp => {
        this.warehouses = resp.data.results
      }).catch(error => {
        console.log(error)
      }).finally()
    },
    // WINES
    getType() {
      const params = {
        store: this.storeSelected,
        warehouse: this.warehouseSelected
      }
      StatsService.getType(params).then(resp => {
        this.wineTypes = resp.data.results
      }).catch(error => {
        console.log(error)
      }).finally()
    },
    save(dates) {
      // const params = {
      //     year: null,
      //     month: null,
      //     rangeDates: dates,
      //     store: this.storeSelected,
      //     stats_type: this.statisticaSelected
      //   }
      const params = {
          year: 2024,
          month: this.getMeseCorrente(),
          date_range: {
            start_date: this.dates[0],
            end_date: this.dates[1]
          },
          store: this.storeSelected,
          stats_type: this.statisticaSelected
        }
        StatsService.getBottleSellDbD(params).then(resp => {
          this.risultato = resp.data
          this.showGrafico = true
        }).catch(error => {
          console.log(error)
        }).finally()
        console.log(dates)
      this.showGrafico = true
      console.log(params)
      // StatsService.getBottleSellDbD(params).then(resp => {
      //   this.risultato = resp.data
      // })
      this.modal = false
    },
  }
}
</script>