<template>
  <v-card class="card-shadow border-radius-xl px-4 py-4" elevation="5">
    <div class="d-flex justify-content-between">
      <h6 class="mb-0 text-h6 text-typo font-weight-bold">Valore Magazzino</h6>
    </div>
    <v-row class="mt-4 py-3">
      <v-col cols="7" class="text-start">
        <div class="chart-area">
          <canvas :height="200" :id="channelsChartID"></canvas>
        </div>
      </v-col>
      <v-col cols="5" class="my-auto">
        <div class="my-2" v-for="(color, index) in backgroundColors" :key="index">
          <v-badge bottom bordered :color="color" dot offset-x="4" offset-y="9"></v-badge>
          <span class="text-dark text-xs ms-3">{{ wineTypes[index] }}</span>
        </div>
      </v-col>
    </v-row>
    <div class="text-body mt-8">
      <p class="text-sm font-weight-light mb-0 w-60">
        <!--        Valore Totale di Magazzino: <span class="font-weight-bold">{{ chartData.total_value }}€</span>-->
        <!--        <span v-for="(item, index) in chartData" :key="index">{{ item }}</span>-->
        <span class="mt-0 mb-1" v-for="item in formattedChartData" :key="item.type">
          Valore {{ translateWineType(item.type) }}:
            <span class="font-weight-bold">{{ formatCurrency(item.value) }}</span> €
          ({{ item.percentage }}%) <br>
        </span>
      </p>
    </div>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";

export default {
  name: "card-channels",
  props: {
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      channelsChartID: "channelsChart",
    };
  },
  computed: {
    wineTypes() {
      return Object.keys(this.chartData)
          .filter(key => key !== "total_value" && !key.includes("_percentage"));
    },
    backgroundColors() {
      // Assicurati che la funzione generateBackgroundColors esista o sia passata come prop
      return this.generateBackgroundColors(this.wineTypes.length);
    },
    formattedChartData() {
      return Object.keys(this.chartData).reduce((acc, key) => {
        // Ignora le chiavi percentuali per costruire i nuovi dati
        if (!key.includes('_percentage')) {
          acc.push({
            type: key,
            value: this.chartData[key],
            percentage: this.chartData[`${key}_percentage`] ? this.chartData[`${key}_percentage`].toFixed(2) : "0.00"
          });
        }
        return acc;
      }, []);
    }

  },
  mounted() {
    this.createChart();
  },
  methods: {
    formatCurrency(value) {
      return value.toLocaleString('it-IT', {minimumFractionDigits: 2, maximumFractionDigits: 2});
    },
    translateWineType(type) {
      const translations = {
        "total_value": "Totale",
        "ROSSO": "Rossi",
        "BIANCO": "Bianchi",
        "BOLLICINA": "Bollicine",
        "ROSÈ": "Rosè",
        "DOLCE": "Dolci",
        "GRAPPA ": "Grappa",
        "SIDRO": "Sidro"
      };
      return translations[type] || type;
    },
    createChart() {
      const dataKeys = Object.keys(this.chartData).filter(key => key !== "total_value");
      const dataValues = dataKeys.map(key => this.chartData[key]);
      const backgroundColors = this.generateBackgroundColors(dataKeys.length);

      new Chart(document.getElementById(this.channelsChartID).getContext("2d"), {
        type: "pie",
        data: {
          labels: dataKeys,
          datasets: [
            {
              label: "Valore Vini",
              data: dataValues,
              backgroundColor: backgroundColors,
              borderWidth: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
          },
          interaction: {
            intersect: false,
            mode: "index",
          },
        },
      });
    },
    generateBackgroundColors(count) {
      // Funzione per generare colori casuali, puoi personalizzarla come preferisci
      const colors = [];
      for (let i = 0; i < count; i++) {
        const color = `hsl(${(i * 360) / count}, 70%, 70%)`;
        colors.push(color);
      }
      return colors;
    },
  },
};
</script>